<template>
  <div class="404">
    404
  </div>
</template>

<script>
export default {
  name: '404',
};
</script>

<style>

</style>
